import {download, get, post} from '../index';

export const entryPage = (data) => {
    return post('/entry/page/', data).then((res) => res);
};

export const imputationInvoice = (data) => {
    return post('/entry/imputationInvoice/', data).then((res) => res);
};
export const entryStatusSave = (data) => {
    return post('/entry/entryStatusSave/', data).then((res) => res);
};

export const entryStatusSubmit = (data) => {
    return post('/entry/entryStatusSubmit/', data).then((res) => res);
};

export const queryInvoiceEntryResult = (data) => {
    return post('/entry/queryInvoiceEntryResult/', data).then((res) => res);
};

export const exportEntry = (data) => {
    return download('/entry/exportEntry/', data).then((res) => {
        let blob = new Blob([res.data], {type: 'application/xlsx'});
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement('a'); // 创建a标签
        link.href = url;
        link.download = '批量发票入账导入模板.xlsx'; // 重命名文件
        link.click();
        URL.revokeObjectURL(url);
    });
};
export const downloadRecords = (data) => {
    return download('/entry/downloadRecords/' + data).then((res) => {
        let blob = new Blob([res.data], {type: 'application/xlsx'});
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement('a'); // 创建a标签
        link.href = url;
        link.download = '同步数据文件.xlsx'; // 重命名文件
        link.click();
        URL.revokeObjectURL(url);
    });
};

export const entryGet = (data) => {
    return get('/entry/get/' + data).then((res) => res);
};

export const getRecordsBySerialNo = (data) => {
    return get('/entry/getRecordsBySerialNo/' + data).then((res) => res);
};