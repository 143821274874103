<template>
  <div class="p-wrap">
    <div class="p-header">
      <p-header-vue spacer border title="发票入账"> </p-header-vue>
      <el-form
        class="select-form"
        ref="selectForm"
        :inline="true"
        :model="selectForm"
        label-width="100px"
      >
        <el-form-item prop="entryPeriod" label="入账属期">
          <el-date-picker
            v-model="selectForm.entryPeriod"
            type="month"
            clearable
            placeholder="请选择入账属期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="purpose" label="入账状态">
          <el-select
            v-model="selectForm.purpose"
            placeholder="请选择入账状态"
            clearable
          >
            <el-option
              v-for="item in entryStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="sellerName" label="销方名称">
          <el-input
            type="text"
            v-model="selectForm.sellerName"
            placeholder="请输入销方名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item prop="record" label="购方名称">
          <el-select
            v-model="selectForm.orgId"
            filterable
            clearable
            @change="generateSelectAgentUsername"
            :filter-method="handleTaxPayerFilter"
          >
            <el-option
              v-for="item in taxPayerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left; color: #666666; padding-right: 15px">{{
                item.name
              }}</span>
              <span style="float: right; color: #3d94ff; font-size: 13px">{{
                item.taxNo
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="invoiceType"
          label="发票类型"
          v-show="selectExpended"
        >
          <el-select
            v-model="selectForm.invoiceType"
            placeholder="请选择发票类型"
            clearable
          >
            <el-option
              v-for="item in invoiceTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="invoiceStatus"
          label="发票状态"
          v-show="selectExpended"
        >
          <el-select
            v-model="selectForm.invoiceStatus"
            placeholder="请选择发票状态"
            clearable
          >
            <el-option
              v-for="item in invoiceStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="source" label="数据来源" v-show="selectExpended">
          <el-select
            v-model="selectForm.source"
            placeholder="请选择数据来源"
            clearable
          >
            <el-option
              v-for="item in sources"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="handleStatus"
          label="处理状态"
          v-show="selectExpended"
        >
          <el-select
            v-model="selectForm.handleStatus"
            placeholder="请选择处理状态"
            clearable
          >
            <el-option
              v-for="item in handleStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="invoiceCode"
          label="发票代码"
          v-show="selectExpended"
        >
          <el-input
            type="text"
            v-model="selectForm.invoiceCode"
            placeholder="请输入发票代码"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item prop="invoiceNo" label="发票号码" v-show="selectExpended">
          <el-input
            type="text"
            v-model="selectForm.invoiceNo"
            placeholder="请输入发票号码"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item prop="invoiceNo" label="数电票号" v-show="selectExpended">
          <el-input
            v-model="selectForm.eInvoiceNo"
            placeholder="请输入数电票号"
            clearable
            type="text"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="entrySerialNo"
          label="入账编号"
          v-show="selectExpended"
        >
          <el-input
            type="text"
            v-model="selectForm.entrySerialNo"
            placeholder="请输入入账编号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="entryUserName"
          label="提交人员"
          v-show="selectExpended"
        >
          <el-input
            type="text"
            v-model="selectForm.entryUserName"
            placeholder="请输入提交人员"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="入账时间" prop="entryTime" v-show="selectExpended">
          <el-date-picker
            v-model="selectForm.entryTime"
            type="date"
            placeholder="请选择入账时间"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="开票日期"
          prop="invoiceTime"
          v-show="selectExpended"
        >
          <el-date-picker
            v-model="selectForm.invoiceTime"
            type="date"
            placeholder="请选择开票日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item style="margin-left: 32px">
          <select-button
            show-status
            @list-close="handleListClose"
            @list-show="handleListShow"
            @select="handleQuery"
            @reset="handleReset"
          ></select-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="p-content" style="padding-top: 15px">
      <div class="main-top">
        <div class="top-left">
          <span>票据列表</span>
          <el-popover placement="right" width="80" trigger="click">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            >
              全选
            </el-checkbox>
            <div class="part"></div>
            <el-checkbox-group
              v-model="colOptions"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in colSelect"
                :label="item"
                :key="item"
                style="display: block; margin-top: 6px"
              ></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="rowSet">
              <img class="imgSet" src="@/assets/icon/set.png" />
              <span>列设置</span>
            </div>
          </el-popover>
        </div>
        <div class="top-right">
          <el-button type="default" @click="entryExport">导出</el-button>
          <el-button type="default" @click="showSyncDialog">数据同步</el-button>
          <el-button
            :disabled="entryDisable"
            type="default"
            @click="showEntryDialog"
            >同步入账结果
          </el-button>
          <el-button
            :disabled="entryDisable"
            type="primary"
            @click="entryDialog"
            >请选择入账用途
          </el-button>
        </div>
      </div>
      <el-table
        :data="records"
        border
        stripe
        v-loading="recordsLoading"
        ref="customerInfoTable"
        @cell-click="handleCellClick"
        @selection-change="handleSelection"
        :header-cell-style="handleHeaderCellStyle"
      >
        <el-table-column type="selection" width="50" fixed="left" />
        <el-table-column label="序号" type="index" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="colData[0] && colData[0].isTrue"
          prop="invoiceNo"
          width="100px"
          label="发票号码"
        ></el-table-column>
        <el-table-column
          v-if="colData[1] && colData[1].isTrue"
          prop="invoiceCode"
          width="120px"
          label="发票代码"
        ></el-table-column>
        <el-table-column
          v-if="colData[2] && colData[2].isTrue"
          label="数电票号"
          prop="eInvoiceNo"
          width="160px"
        ></el-table-column>
        <el-table-column
          v-if="colData[3] && colData[3].isTrue"
          prop="invoiceType"
          min-width="240px"
          label="发票类型"
          :formatter="renderTypes"
        ></el-table-column>
        <el-table-column
          v-if="colData[4] && colData[4].isTrue"
          prop="entrySerialNo"
          label="入账编号"
          width="200px"
        ></el-table-column>
        <el-table-column
          v-if="colData[5] && colData[5].isTrue"
          prop="amount"
          label="发票金额"
          width="120px"
        ></el-table-column>
        <el-table-column
          v-if="colData[6] && colData[6].isTrue"
          prop="taxAmount"
          width="120px"
          label="发票税额"
        ></el-table-column>
        <el-table-column
          v-if="colData[7] && colData[7].isTrue"
          prop="invoiceStatus"
          width="100px"
          label="发票状态"
          :formatter="renderStatus"
        ></el-table-column>
        <el-table-column
          v-if="colData[8] && colData[8].isTrue"
          prop="sellerName"
          width="140px"
          label="销方名称"
        ></el-table-column>
        <el-table-column
          v-if="colData[9] && colData[9].isTrue"
          prop="buyerName"
          width="140px"
          label="购方名称"
        ></el-table-column>
        <el-table-column
          v-if="colData[10] && colData[10].isTrue"
          prop="invoiceTime"
          width="180px"
          label="开票日期"
        ></el-table-column>
        <el-table-column
          v-if="colData[11] && colData[11].isTrue"
          prop="handlerStatus"
          width="100px"
          label="处理状态"
          :formatter="handlerFormatter"
        ></el-table-column>
        <el-table-column
          v-if="colData[12] && colData[12].isTrue"
          prop="handlerResult"
          width="100px"
          label="处理结果"
        ></el-table-column>
        <el-table-column
          v-if="colData[13] && colData[13].isTrue"
          prop="creatorName"
          width="100px"
          label="提交人员"
        ></el-table-column>
        <el-table-column
          v-if="colData[14] && colData[14].isTrue"
          prop="submitTime"
          width="100px"
          label="提交时间"
        ></el-table-column>
        <el-table-column
          v-if="colData[15] && colData[15].isTrue"
          :formatter="entryStatusFormatter"
          label="入账状态"
          prop="purpose"
          width="120px"
        ></el-table-column>
        <el-table-column
          v-if="colData[16] && colData[16].isTrue"
          prop="entryPeriod"
          width="100px"
          label="入账属期"
        ></el-table-column>
        <el-table-column
          v-if="colData[17] && colData[17].isTrue"
          prop="entryTime"
          width="100px"
          label="入账时间"
        ></el-table-column>
        <el-table-column
          v-if="colData[18] && colData[18].isTrue"
          prop="serialNo"
          width="120px"
          label="同步编号"
        >
        </el-table-column>
        <el-table-column
          v-if="colData[19] && colData[19].isTrue"
          prop="dataType"
          width="100px"
          label="数据类型"
          :formatter="renderDataType"
        ></el-table-column>
        <el-table-column
          v-if="colData[20] && colData[20].isTrue"
          prop="source"
          width="100px"
          label="数据来源"
          :formatter="renderSource"
        ></el-table-column>
        <el-table-column
          v-if="colData[21] && colData[21].isTrue"
          prop="syncStart"
          width="180px"
          label="同步起始"
        ></el-table-column>
        <el-table-column
          v-if="colData[22] && colData[22].isTrue"
          prop="syncEnd"
          width="180px"
          label="同步终止"
        ></el-table-column>
        <!--        <el-table-column v-if="colData[21] && colData[21].isTrue" prop="operate" label="操作" width="80" fixed="right">-->
        <!--          <template slot-scope="scope">-->
        <!--            <div class="operate-button">-->
        <!--              <el-button type="text" size="medium" @click="entryDetail(scope.row)">详情</el-button>-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>

      <el-pagination
        class="p-page"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="paging.current"
        :page-sizes="pageSizes"
        :page-size="paging.size"
        layout="total, sizes, prev, pager, next"
        :total="paging.total"
      ></el-pagination>
    </div>

    <el-dialog
      title="发票数据同步"
      :visible.sync="dialogVisible"
      min-width="400px"
      width="40%"
    >
      <el-form
        ref="syncForm"
        :model="syncForm"
        :rules="syncRules"
        label-width="100px"
      >
        <el-form-item label="纳税主体" prop="record">
          <el-select
            v-model="syncForm.record.orgId"
            filterable
            clearable
            @change="generateSelectAgentUsername"
            :filter-method="handleTaxPayerFilter"
          >
            <el-option
              v-for="item in taxPayerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left; color: #666666; padding-right: 15px">{{
                item.name
              }}</span>
              <span style="float: right; color: #3d94ff; font-size: 13px">{{
                item.taxNo
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票日期" prop="invoiceTime">
          <el-date-picker
            v-model="syncForm.invoiceTime"
            type="daterange"
            range-separator="~"
            size="mini"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoiceType">
          <el-select
            v-model="syncForm.invoiceType"
            placeholder="请选择发票类型"
          >
            <el-option
              v-for="item in invoiceTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" prop="dataType">
          <el-select v-model="syncForm.dataType" placeholder="请选择数据类型">
            <el-option
              v-for="item in dataTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="imputationSync('syncForm')"
          >确 认</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="同步入账结果"
      :visible.sync="entryDialogVisible"
      min-width="400px"
      width="40%"
    >
      <el-form ref="entryForm" :model="entryForm" :rules="rules">
        <el-form-item label="纳税主体" prop="record">
          <el-select
            v-model="entryForm.record.orgId"
            filterable
            clearable
            @change="generateSelectAgentUsername"
            :filter-method="handleTaxPayerFilter"
          >
            <el-option
              v-for="item in taxPayerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left; color: #666666; padding-right: 15px">{{
                item.name
              }}</span>
              <span style="float: right; color: #3d94ff; font-size: 13px">{{
                item.taxNo
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="入账流水号：" prop="serialNo" style="padding-top: 15px;">-->
        <!--          <el-input v-model="entryForm.serialNo" placeholder="请输入入账流水号" type="text"-->
        <!--                    style="margin-left: -20px;"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item
          label="数电票号"
          prop="serialNo"
          style="padding-top: 15px"
        >
          <el-input
            v-model="entryForm.eInvoiceNo"
            placeholder="请输入数电票号"
            type="text"
            style="margin-left: -20px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="entryDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="entryQuery('entryForm')"
          >确 认</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="查看同步数据"
      :visible.sync="dialogTableVisible"
      min-width="400px"
      width="40%"
    >
      <el-table
        :data="pullRecords"
        :header-cell-style="{
          background: 'rgb(235, 244, 255)',
          color: '#606266',
        }"
        style="padding: 0 20px 40px 20px"
      >
        <el-table-column
          property="serialNo"
          label="同步编号"
          width="300"
        ></el-table-column>
        <el-table-column property="total" label="总数"></el-table-column>
        <el-table-column
          property="handleCount"
          label="已处理数"
        ></el-table-column>
        <el-table-column
          property="unHandleCount"
          label="未处理数"
        ></el-table-column>
        <el-table-column
          property="success"
          label="同步状态"
          :formatter="pullFormatter"
        ></el-table-column>
      </el-table>
      <div>
        <el-button type="primary" @click="downloadRecords"
          >下载失败信息</el-button
        >
      </div>
    </el-dialog>

    <invoice-entry-detail
      :show.sync="drawer"
      :entryObj="entryObj"
      @refreshDetail="refreshDetail"
    />

    <el-dialog
      title="请选择入账用途"
      :visible.sync="entryVisible"
      min-width="400px"
      width="40%"
    >
      <el-form label-width="120px">
        <el-form-item label="入账用途">
          <el-cascader
            style="width: 200px"
            v-model="purpose"
            :options="purposeArr"
            placeholder="请选择入账用途"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="入账提示">
          <span style="font-size: 16px; color: red"> {{ entryMessage }}</span>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="entryVisible = false">取 消</el-button>
        <el-button @click="entrySave">保 存</el-button>
        <el-button type="primary" @click="entrySubmit">入 账</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import PHeaderVue from "@/components/pdt/PHeader.vue";
import OrganizationSelect from "@/components/widgets/OrganizationSelect.vue";
// import PSelectVue from "@/components/pdt/PSelect.vue";
import {
  downloadRecords,
  entryGet,
  entryPage,
  entryStatusSave,
  entryStatusSubmit,
  exportEntry,
  getRecordsBySerialNo,
  imputationInvoice,
  queryInvoiceEntryResult,
} from "@/service/entry/entry-invoice";
import {
  changeMethods,
  dateOption,
  pagingMethods,
  tableStyle,
} from "@/util/mixins";
import { ENTRY_COLUMN } from "@/content/entry";
import { selectOrganization } from "@/service/organization";
import { INVOICE_TYPES } from "@/content/invoice";
import InvoiceEntryDetail from "@/views/entry/invoice-entry-detail";
import { getUser } from "@/service/sys/user";
import moment from "moment";

export default {
  components: {
    InvoiceEntryDetail,
    OrganizationSelect,
    PHeaderVue,
  },
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  data() {
    return {
      selectExpended: false,

      allTaxPayerList: [],
      taxPayerList: [],

      serialNo: "",
      currentMonth: this.getCurrentMonth(),
      syncRules: {
        record: [
          { required: true, message: "请选择纳税主体", trigger: "blur" },
        ],
        invoiceTime: [
          { required: true, message: "请选择开票日期", trigger: "change" },
        ],
      },
      rules: {
        record: [
          { required: true, message: "请选择入账组织", trigger: "blur" },
        ],
        // serialNo: [
        //   {required: true, message: '请输入入账流水号', trigger: 'blur'},
        // ],
        eInoviceNo: [
          { required: true, message: "请输入数电发票号", trigger: "blur" },
        ],
      },
      purposeArr: [
        // {
        //   value: '00',
        //   label: '未入账',
        //   disabled: false
        // },
        {
          value: "02",
          label: "入账（企业所得税税前扣除)",
          disabled: false,
        },
        {
          value: "03",
          label: "入账（企业所得税不扣除）",
          disabled: false,
        },
        {
          value: "04",
          label: "入账（个人所得税经营所得税前扣除）",
          disabled: false,
        },
        {
          value: "05",
          label: "入账（个人所得税经营所得不扣除）",
          disabled: false,
        },
        {
          value: "06",
          label: "入账撤销",
          disabled: false,
        },
      ],
      entryMessage: "",
      purpose: "",
      entryVisible: false,
      entryDisable: true,
      entryObj: {},
      drawer: false,
      dialogTableVisible: false,
      pullRecords: [],
      invoiceTypes: INVOICE_TYPES,
      handleStatus: [
        {
          value: "0",
          label: "正在处理",
        },
        {
          value: "1",
          label: "处理成功",
        },
        {
          value: "2",
          label: "处理失败",
        },
        {
          value: "3",
          label: "正在同步",
        },
        {
          value: "4",
          label: "同步成功",
        },
        {
          value: "5",
          label: "同步失败",
        },
      ],
      sources: [
        {
          value: "0",
          label: "本地数据",
        },
        {
          value: "1",
          label: "局端数据",
        },
      ],
      dataTypes: [
        {
          value: "1",
          label: "进项发票",
        },
        {
          value: "2",
          label: "销项发票",
        },
      ],
      entryStatus: [
        {
          value: "01",
          label: "未入账",
        },
        {
          value: "02",
          label: "入账（企业所得税税前扣除)",
        },
        {
          value: "03",
          label: "入账（企业所得税不扣除）",
        },
        {
          value: "04",
          label: "入账（个人所得税经营所得税前扣除）",
        },
        {
          value: "05",
          label: "入账（个人所得税经营所得不扣除）",
        },
        {
          value: "06",
          label: "入账撤销",
        },
      ],
      invoiceStatus: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "2",
          label: "作废",
        },
        {
          value: "3",
          label: "红字（一次性冲红）",
        },
        {
          value: "7",
          label: "已部分冲红",
        },
        {
          value: "8",
          label: "已全额红冲",
        },
      ],
      dialogVisible: false,
      entryDialogVisible: false,
      colData: ENTRY_COLUMN,
      isIndeterminate: true,
      colOptions: [], //列选项
      checkAll: false, //全选
      colSelect: ENTRY_COLUMN.map((i) => i.title),
      selectForm: {
        orgId: undefined,
        name: undefined,
        id: undefined,
        entryPeriod: undefined,
        entryStatus: undefined,
        sellerName: undefined,
        buyerName: undefined,
        invoiceType: undefined,
        invoiceStatus: undefined,
        source: undefined,
        handleStatus: undefined,
        invoiceCode: undefined,
        invoiceNo: undefined,
        eInvoiceNo: undefined,
        entryUserName: undefined,
        entryTime: undefined,
        invoiceTime: undefined,
        entrySerialNo: undefined,
        purpose: undefined,
      },
      syncForm: {
        invoiceTime: undefined,
        record: {
          orgId: undefined,
          orgName: undefined,
        },
        invoiceType: undefined,
        dataType: "1",
      },
      entryForm: {
        record: {
          orgId: undefined,
          orgName: undefined,
        },
        serialNo: undefined,
        eInvoiceNo: undefined,
      },
      recordsLoading: false,
      records: [],
      paging: {
        current: 1,
        size: 15,
        total: 0,
        searchCount: true,
        pages: 1,
      },
      selections: [],
      pageSizes: [15, 30, 50, 100],
    };
  },
  mounted() {
    this.currentOrg();
    this.handleQuery();
    this.btnDisable();
  },
  created() {
    // this.invoiceTypes = [{ value: "", label: "全部" }].concat(
    //   this.invoiceTypes
    // );

    this.allTaxPayerList = this.$store.state.user.organizations.filter(
      (item) => item.isTaxpayer == "Y"
    );
    this.taxPayerList = JSON.parse(JSON.stringify(this.allTaxPayerList));

    this.handleGetlineSet();
  },
  watch: {
    colOptions(valArr) {
      const arr = this.colSelect.filter((i) => valArr.indexOf(i) < 0); // 未选中
      this.colData.filter((i) => {
        if (arr.indexOf(i.title) != -1) {
          i.isTrue = false;
          this.$nextTick(() => {
            this.$refs.customerInfoTable.doLayout();
          });
        } else {
          i.isTrue = true;
          this.$nextTick(() => {
            this.$refs.customerInfoTable.doLayout();
          });
        }
      });
      localStorage.setItem("entryInfo", JSON.stringify(this.colData));
    },
  },
  methods: {
    // 选择纳税主体过滤方法
    handleTaxPayerFilter(val) {
      val = val.toUpperCase();
      this.taxPayerList = this.allTaxPayerList.filter(
        (item) => item.name.indexOf(val) != -1 || item.taxNo.indexOf(val) != -1
      );
    },

    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    btnDisable() {
      //未选择 初始化
      if (this.selections.length === 0) {
        if (
          this.currentMonth !== this.formatMonth(this.selectForm.entryPeriod)
        ) {
          console.log("currentMonth disable", this.currentMonth);
          this.entryDisable = true;
          return;
        }
        console.log("selections disable", this.selections.length);
        this.entryDisable = true;
      } else {
        this.entryDisable = false;
      }

      //选择
      if (this.selections.length > 0) {
        let bool = this.selections.every(
          (s) => s.entryPeriod === "" || s.entryPeriod === this.currentMonth
        );
        if (!bool) {
          console.log("every period disable", bool);
          this.entryDisable = true;
        } else {
          this.entryDisable = false;
        }
      }
    },
    handlerDialogVisible() {
      this.dialogVisible = true;
      this.syncForm = {
        invoiceTime: undefined,
        record: {
          orgId: undefined,
          orgName: undefined,
        },
        invoiceType: undefined,
        dataType: "1",
      };
    },
    handlerEntryDialogVisible() {
      this.entryDialogVisible = true;
      this.entryForm = {
        serialNo: undefined,
        record: {
          orgId: undefined,
          orgName: undefined,
        },
      };
      console.log(this.entryForm, "this.entryForm");
    },
    getCurrentMonth() {
      let date = new Date();
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 > 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      console.log(year + "-" + month, "year + " - " + month");
      return year + "-" + month;
    },
    formatMonth(v) {
      return moment(v).format("YYYY-MM");
    },
    downloadRecords() {
      const { success } = downloadRecords(this.serialNo);
      if (success) {
        this.toast("下载成功！", "success");
      }
    },
    async currentOrg() {
      const { success, data } = await getUser();
      if (success) {
        this.selectForm.orgId = data.orgId;
      }
    },
    entryStatusFormatter(row) {
      // console.log(row)
      switch (row.purpose) {
        /*
        {
          value: '00',
          label: '未入账',
        },
        {
          value: '02',
          label: '入账（企业所得税税前扣除)',
        },
        {
          value: '03',
          label: '入账（企业所得税不扣除）',
        },
        {
          value: '04',
          label: '入账（个人所得税经营所得税前扣除）',
        },
        {
          value: '05',
          label: '入账（个人所得税经营所得不扣除）',
        },
        {
          value: '06',
          label: '入账撤销',
        }
           */
        case "01":
          return "未入账";
        case "02":
          return "入账（企业所得税税前扣除）";
        case "03":
          return "入账（企业所得税不扣除）";
        case "04":
          return "入账（个人所得税经营所得税前扣除）";
        case "05":
          return "入账（个人所得税经营所得不扣除）";
        case "06":
          return "入账撤销";
        default:
          return "未入账";
      }
    },
    entryQuery(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handlerSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showSyncDialog() {
      this.syncForm = {
        invoiceTime: undefined,
        record: {
          orgId: undefined,
          orgName: undefined,
        },
        invoiceType: undefined,
        dataType: "1",
      };
      this.dialogVisible = true;
    },
    showEntryDialog() {
      this.entryForm = {
        record: {
          orgId: undefined,
          orgName: undefined,
        },
        serialNo: undefined,
        eInvoiceNo: undefined,
      };
      this.entryDialogVisible = true;
    },
    async handlerSubmit() {
      const { success } = await queryInvoiceEntryResult({
        orgId: this.entryForm.record.orgId,
        eInvoiceNo: this.entryForm.eInvoiceNo,
      });
      // console.log(success)
      if (success) {
        this.entryForm = {
          record: {
            orgId: undefined,
            orgName: undefined,
          },
          serialNo: undefined,
          eInoviceNo: undefined,
        };
        this.entryDialogVisible = false;
        this.toast("入账结果已同步成功", "success");
        this.handleQuery();
      }
    },
    handlerFormatter(row) {
      // console.log(row)
      switch (row.handlerStatus) {
        case "0":
          return "正在处理";
        case "1":
          return "处理成功";
        case "2":
          return "处理失败";
        case "3":
          return "正在同步";
        case "4":
          return "同步成功";
        case "5":
          return "同步失败";
        default:
          return "";
      }
    },
    entryBefore() {
      let arr = this.selections.map((item) => item.purpose);
      this.purpose = arr[0];
      if (this.purpose === "") {
        this.purpose = "00";
      }

      if (this.purpose === "00") {
        this.purposeArr[4].disabled = true;
        return;
      } else {
        this.purposeArr[4].disabled = false;
      }
      if (
        this.purpose === "02" ||
        this.purpose === "03" ||
        this.purpose === "04" ||
        this.purpose === "05"
      ) {
        // this.purposeArr[0].disabled = true
        return;
      } else {
        // this.purposeArr[0].disabled = false
      }
      if (this.purpose === "06") {
        // this.purposeArr[0].disabled = true
        this.purposeArr[4].disabled = true;
      } else {
        // this.purposeArr[0].disabled = false
        this.purposeArr[4].disabled = false;
      }
    },
    async entrySubmit() {
      let arr = [];
      arr = this.selections.map((item) => item.id);
      console.log(this.purpose);
      const { success } = await entryStatusSubmit({
        ids: arr,
        purpose: this.purpose instanceof Array ? this.purpose[0] : this.purpose,
      });
      if (success) {
        this.toast("提交成功", "success");
      }
      this.entryVisible = false;
      this.handleQuery();
    },
    async entrySave() {
      let arr = [];
      arr = this.selections.map((item) => item.id);
      console.log(this.purpose);
      const { success } = await entryStatusSave({
        ids: arr,
        purpose: this.purpose instanceof Array ? this.purpose[0] : this.purpose,
      });
      if (success) {
        this.toast("保存成功", "success");
        this.purpose = [];
      }
      this.entryVisible = false;
      this.handleQuery();
    },
    entryDialog() {
      let sum = 0;
      this.selections.forEach((item) => (sum += item.taxAmount));
      this.entryMessage =
        "本次勾选发票" +
        this.selections.length +
        "张,税额" +
        sum +
        "元,是否确认提交?";
      this.entryBefore();
      this.entryVisible = true;
    },
    entryExport() {
      let arr = [];
      arr = this.selections.map((i) => i.id);
      if (arr.length === 0) {
        this.$confirm("请选择一条数据", "提示");
        return;
      }
      const { success } = exportEntry(arr);
      if (success) {
        this.toast("导出成功！", "success");
      }
    },
    entryDetail(row) {
      this.refreshDetail(row);
      this.drawer = true;
    },
    async refreshDetail(row) {
      this.entryObj = {};
      if (!row.id) {
        return;
      }
      const { success, data } = await entryGet(row.id);
      if (success) {
        this.entryObj = data;
      }
    },
    pullFormatter(row) {
      if (row.success) {
        return "同步成功";
      } else {
        return "同步失败";
      }
    },
    async handleCellClick(row, column, event) {
      this.serialNo = row.serialNo;
      if (column.property === "serialNo" && row.serialNo) {
        this.pullRecords = [];
        const { success, data } = await getRecordsBySerialNo(row.serialNo);
        if (success) {
          this.pullRecords = data;
        }
        this.dialogTableVisible = true;
      }
    },
    renderSource(row, col) {
      switch (row.source) {
        case 0:
          return "本地数据";
        case 1:
          return "局端数据";
        default:
          return "";
      }
    },
    renderDataType(row, col) {
      switch (row.dataType) {
        case "1":
          return "进项发票";
        case "2":
          return "销项发票";
        default:
          return "";
      }
    },
    renderStatus(row, col) {
      switch (row.invoiceStatus) {
        case "0":
          return "正常";
        case "2":
          return "作废";
        case "3":
          return "已红冲";
        case "7":
          return "已部分冲红";
        case "8":
          return "已全额冲红";
        default:
          return "";
      }
    },
    renderTypes(row, col) {
      // console.log(row.invoiceType)
      for (let t of INVOICE_TYPES) {
        // console.log(t)
        if (t.value === row.invoiceType) {
          return t.label;
        }
      }
      return "";
    },
    async imputationInvoice() {
      const { success } = await imputationInvoice({
        orgId: this.syncForm.record.orgId,
        syncStart: this.syncForm.invoiceTime[0],
        syncEnd: this.syncForm.invoiceTime[1],
      });
      if (success) {
        this.toast("同步成功", "success");
      }
      this.dialogVisible = false;
      this.syncForm.record.orgId = "";
      this.syncForm.record.orgName = "";
      this.syncForm.dataType = "1";
      this.syncForm.invoiceTime = "";
      this.syncForm.invoiceType = "";
      this.handleQuery();
    },
    async imputationSync(formName) {
      if (!this.syncForm.record.orgId) {
        this.$confirm("请选择纳税主体", "提示", {
          type: "warning",
        });
        return;
      }
      if (!this.syncForm.invoiceTime) {
        this.$confirm("请选择开票日期", "提示", {
          type: "warning",
        });
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.imputationInvoice();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 补全助手名称
    async generateSelectAgentUsername(e) {
      // if (e) {
      //   this.selectForm.orgId = e.orgId;
      // }
      // let orgId = this.selectForm.orgId;
      // if (!orgId) {
      //   this.selectForm.buyerName = "";
      //   return;
      // }
      // const { success, data } = await selectOrganization(this.selectForm.orgId);
      // if (success) {
      //   this.selectForm.buyerName = data.name;
      // } else {
      //   this.selectForm.buyerName = "";
      // }
    }, // 补全助手名称
    async generateEntryAgentUsername(e) {
      if (e) {
        this.entryForm.record.orgId = e.orgId;
      }
    },
    // 补全助手名称
    async generateAgentUsername(e) {
      if (e) {
        this.entryForm.record.orgId = e.orgId;
      }
    },
    //获取列设置
    handleGetlineSet() {
      if (JSON.parse(localStorage.getItem("entryInfo")) != null) {
        this.colData = JSON.parse(localStorage.getItem("entryInfo"));
        if (
          this.colData.filter((i) => i.isTrue == true).length ===
          this.colSelect.length
        ) {
          this.checkAll = true;
          this.isIndeterminate = false;
        }
        this.colOptions = JSON.parse(localStorage.getItem("entryInfo"))
          .filter((i) => i.isTrue === true)
          .map((item) => item.title); //选中
      } else {
        this.colOptions = this.colData.map((item) => item.title); //默认所有
        this.checkAll = true;
        this.isIndeterminate = false;
      }
    },
    //重置列设置
    handleCheckReset() {
      this.colOptions = this.colSelect;
      this.checkAll = true;
      this.isIndeterminate = false;
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : [];
      this.isIndeterminate = false;
    },
    //列设置改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colSelect.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.colSelect.length;
    },
    //表单重置
    handleReset() {
      this.selectForm = {
        orgId: undefined,
        orgName: undefined,
        id: undefined,
        entryPeriod: undefined,
        entryStatus: undefined,
        sellerName: undefined,
        buyerName: undefined,
        invoiceType: undefined,
        invoiceStatus: undefined,
        source: undefined,
        handleStatus: undefined,
        invoiceCode: undefined,
        invoiceNo: undefined,
        eInvoiceNo: undefined,
        entryUserName: undefined,
        entryTime: undefined,
        invoiceTime: undefined,
        entrySerialNo: undefined,
        purpose: undefined,
      };
      this.currentOrg();
    },
    handleSelection(value) {
      this.selections = value;
      this.btnDisable();
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.paging.size = val;
      this.handleQuery();
    },

    handleCurrentChange(val) {
      this.paging.current = val;
      this.handleQuery();
    },
    //查询
    async handleQuery() {
      let param = {};
      console.log(this.selectForm);
      if (this.selectForm.entryStatus) {
        param.entryStatus = this.selectForm.entryStatus;
      }
      console.log(this.selectForm);
      if (this.selectForm.buyerName) {
        param.buyerName = this.selectForm.buyerName;
      }
      if (this.selectForm.sellerName) {
        param.sellerName = this.selectForm.sellerName;
      }
      if (this.selectForm.entryTime) {
        param.entryTime = this.selectForm.entryTime;
      }
      if (this.selectForm.entryUserName) {
        param.entryUserName = this.selectForm.entryUserName;
      }
      if (this.selectForm.handleStatus) {
        param.handleStatus = this.selectForm.handleStatus;
      }
      if (this.selectForm.invoiceCode) {
        param.invoiceCode = this.selectForm.invoiceCode;
      }
      if (this.selectForm.invoiceNo) {
        param.invoiceNo = this.selectForm.invoiceNo;
      }
      if (this.selectForm.eInvoiceNo) {
        param.eInvoiceNo = this.selectForm.eInvoiceNo;
      }
      if (this.selectForm.invoiceType) {
        param.invoiceType = this.selectForm.invoiceType;
      }
      if (this.selectForm.source) {
        param.source = this.selectForm.source;
      }
      if (this.selectForm.entryPeriod) {
        param.entryPeriod = this.selectForm.entryPeriod;
      }
      if (this.selectForm.invoiceStatus) {
        param.invoiceStatus = this.selectForm.invoiceStatus;
      }
      if (this.selectForm.invoiceTime) {
        param.invoiceTime = this.selectForm.invoiceTime;
      }
      if (this.selectForm.entrySerialNo) {
        param.entrySerialNo = this.selectForm.entrySerialNo;
      }
      if (this.selectForm.purpose !== "") {
        param.purpose = this.selectForm.purpose;
      }

      param.current = this.paging.current;
      param.size = this.paging.size;
      this.recordsLoading = true;
      this.paging.searchCount = true;
      const { success, data } = await entryPage(param);
      if (success) {
        this.records = data.records.map((item) => {
          item.isShowPsd = false;
          return item;
        });
        this.paging.total = data.total;
      }
      this.recordsLoading = false;
    },
  },
};
</script>
<style scoped lang="scss">
.invoice-dialog {
  div {
    margin-bottom: 12px;
  }

  span {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  .el-image {
    width: 200px;
    height: 200px;
  }
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .el-button {
    padding: 0;
  }
}

.append-btn {
  width: 135px;
}

.operate-button {
  display: flex;
  justify-content: center;

  .el-button {
    padding: 0;
    margin-right: 10px;
  }
}

.p-header {
  background-color: #fff;
}

.select-form {
  padding: 24px 24px 0px 24px;
}

.p-content {
  padding-top: 0;

  ::v-deep .el-checkbox__label {
    display: none;
  }

  .table-btn {
    padding: 0 !important;
  }
}

.view {
  position: absolute;
  top: 10px;
  left: 88px;
}

::v-deep .el-dialog__header {
  display: block;
  padding: 20px 20px 10px;
}
::v-deep .el-dialog__body {
  padding: 30px 20px;
  text-align: initial;
}
::v-deep .el-form-item {
  margin-bottom: 22px;
}
::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
</style>
